import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index.js";
import store from "../store";
import axios from "axios";
import Institute from "./institute/index";
import customNaming from "./custom-naming/index";

Vue.use(Vuex);
// Vue.use(router)

export default new Vuex.Store({
  modules: {
    institute: Institute,
    customNaming,
  },
  state: {
    //Main API
    staffCount: "",
    studentCount: "",
    totalInstitutionUsers: "",
    username: "",
    profileDetails: {},
    createInstitute: process.env.VUE_APP_CAN_CREATE_INSTITUTE, //enable create institutte module
    selectedInstitute: {},
    defaultInstituteId: null,
    defaultInstitutionUserId: null,
    addInstitute: false,
    instituteApprovalPending: false,
    instituteSettings: false,
    smsCount: {},
    // smsBalance: null,
    // filteredUniqueInstitutions: {},
    myProfile: {},
    user: {},
    mainAPI: process.env.VUE_APP_BACE_API_URL,
    // mainAPI: "http://localhost/EzyGoApi/public/api/v1",
    //Routes
    attainment: {
      co: {},
      studentList: {},
      assignment: {},
      assessment: {},
    },
    formsApprovals: {
      status: false,
    },
    userRole: null,
    userAttachments: "https://storage.googleapis.com/ezygo_user_uploads/",
    myInstitutionsEnrollments: [],
    findUser: "/login/lookup",
    loginUser: "/login",
    userDetails: "/user",
    registerUser: "/register",
    verifyEmail: "/email/verify/",
    verifyMobile: "/mobile/verify",
    reVerifyEmail: "/email/resend",
    reVerifyMobile: "/mobile/resend",
    changeEmail: "/user/email",
    changeMobile: "/user/mobile",
    resetPasswordOptions: "/password/reset/options",
    resetPasswordVia: "/password/reset/request",
    resetPassword: "/password/reset",
    checkUserProfile: "/myprofile",
    createProfile: "/userprofiles/",
    createInstituteName: "/institutions",
    updataInstitution: "/institutions/",
    defaultInstitute: "/user/setting/default_institute",
    userInvitations: "/userinvitations",
    institutionRoles: "/institution/{institution_id}/roles",
    listInvitees: "/institution/invitees",
    acceptInvitation: "/userinvitation/accept",
    institutionUsers: "/institutionusers",
    approveUser: "/institutionusers/approve",
    institutionUserProfile: "/institutionusers/",
    instituitionFaculty: "/institutionusers/faculties",
    instituitionAdmin: "/institutionusers/admins",
    instituitionStudents: "/institutionusers/students",
    createGroup: "/usergroups",
    createAdmin: `/usergroup/usergroup_id/admins`,
    createAdminSubgroup: "/usersubgroup/usersubgroup_id/admins",
    getUserGroupList: "/usergroups/withadmins",
    createSubGroup: "/usergroup/usergroup_id/usersubgroups",
    enrollGroupUser: "/usergroup/usergroup_id/teachers",
    enrollSubGroupUser: "/usersubgroup/usersubgroup_id/students",
    deleteGroupUser: "/usergroupusers/usergroup_user_id",
    deleteSubGroupUser: "/usersubgroupusers/usersubgroup_user_id",
    getEnrollUsersList: "/usergroup/usergroup_id/users",
    getEnrollSubgroupUsersList: "/usersubgroup/usersubgroup_id/users",
    updateSubgroup: "/usersubgroups/usersubgroups_id",
    groupSubgroups: "/usergroups/withsubgroups",
    createCourse: "/courses",
    updateCourse: "/courses/course_id",
    getCourseWithUsers: "/course/course_id/users",
    createIntructor: "/course/course_id/instructors",
    createStudent: "/course/course_id/students",
    deleteCourseUser: "/courseusers/course_user_id",
    attendenceType: "/attendancetypes",
    createworkdays: "/workdays",
    createSessions: "/sessions",
    attendenceDates: "/attendancedates",
    attendences: "/attendances",
    attendencesList: "/attendancedate/attendance_id/attendances",
    lessonplans: "/lessonplans",
    lessonList: "/course/course_id/lessonplans",
    deliveryType: "/coursedeliverymethods",
    attachCourseLessonPlan:
      "/attendancedates/attendancedateId/attach/lessonplans",
    detachCourseLessonPlan:
      "/attendancedates/attendancedateId/detach/lessonplans",
    attachDeliveryType:
      "/attendancedates/attendancedateId/attach/coursedeliverymethods",
    detachDeliveryType:
      "/attendancedates/attendancedateId/detach/coursedeliverymethods",
    getAttendenceDatesLessonPlans:
      "/attendancedates/attendancedateId/lessonplans",
    getAttendenceDatesDeliveryTypes:
      "/attendancedates/attendancedateId/coursedeliverymethods",
    institutionSettings: "/institution/setting/setting_key",
    programOutcome: "/programmeoutcomes",
    programSpecificOutcome: "/programmespecificoutcomes",
    courseOutcomes: "/courseoutcomes",
    courseModules: "/coursemodules",
    getCourseOutcomes: "/course/course_id/courseoutcomes",
    getCourseModules: "/course/course_id/coursemodules",
    copoMapping: "/copomappings/syncbyco",
    getcopoMapping: "/course/course_id/copomappings",
    copsoMapping: "/copsomappings/syncbyco",
    getcopsoMapping: "/course/course_id/copsomappings",
    emailTransaction: "/mailtransaction/sent",
    examination: "/exams",
    universityexamination: "/universityexams",
    examQuestions: "/examquestions",
    examChoices: "/examchoices",
    examAnswers: "/examanswers",
    googleEvent: "/googleevents",
    assignment: "/assignments",
    assignmentQuestions: "/assignmentquestions",
    assignmentAnswers: "/assignmentresponses",
    studentFeedbackSurvey: "/studfbsurveys",
    studentFeedbackSurveySyncTeachers:
      "/studfbsurveys/survey_id/studfbsurveycourseteacher/sync",
    studentFeedbackSurveySyncParticipants:
      "/studfbsurveys/survey_id/studfbsurveystudents/sync",
    surveyQuestions: "/surveyquestions",
    examCategory: "/examcategorys",
    surveyApprovals: "/onlineregistrations",
    approvalQuestions: "/onlineregquestions",
  },
  getters: {
    getApprovalStatus(state) {
      return {
        formsApprovals: state.formsApprovals,
      };
    },
    getAttainment(state) {
      return {
        attainment: state.attainment,
      };
    },

    getUsersCount(state) {
      return {
        staffCount: state.staffCount,
        studentCount: state.studentCount,
        totalInstitutionUsers: state.totalInstitutionUsers,
      };
    },
    // createInstitute(state) {
    //   return state.createInstitute
    // }
    defaultInstitute(state) {
      return {
        instituteSettings: state.instituteSettings,
        userRole: state.userRole,
        createInstitute: state.createInstitute,
      };
    },

    getAPIKey(state) {
      return {
        mainAPI: state.mainAPI,
        userAttachments: state.userAttachments,
        findUser: state.mainAPI + state.findUser,
        loginUser: state.mainAPI + state.loginUser,
        userDetails: state.mainAPI + state.userDetails,
        registerUser: state.mainAPI + state.registerUser,
        verifyEmail: state.mainAPI + state.verifyEmail,
        verifyMobile: state.mainAPI + state.verifyMobile,
        reVerifyEmail: state.mainAPI + state.reVerifyEmail,
        reVerifyMobile: state.mainAPI + state.reVerifyMobile,
        changeEmail: state.mainAPI + state.changeEmail,
        changeMobile: state.mainAPI + state.changeMobile,
        resetPasswordOptions: state.mainAPI + state.resetPasswordOptions,
        resetPasswordVia: state.mainAPI + state.resetPasswordVia,
        resetPassword: state.mainAPI + state.resetPassword,
        checkUserProfile: state.mainAPI + state.checkUserProfile,
        createProfile: state.mainAPI + state.createProfile,
        createInstituteName: state.mainAPI + state.createInstituteName,
        updataInstitution: state.mainAPI + state.updataInstitution,
        defaultInstitute: state.mainAPI + state.defaultInstitute,
        userInvitations: state.mainAPI + state.userInvitations,
        listInvitees: state.mainAPI + state.listInvitees,
        acceptInvitation: state.mainAPI + state.acceptInvitation,
        institutionUsers: state.mainAPI + state.institutionUsers,
        approveUser: state.mainAPI + state.approveUser,
        institutionUserProfile: state.mainAPI + state.institutionUserProfile,
        instituitionFaculty: state.mainAPI + state.instituitionFaculty,
        instituitionAdmin: state.mainAPI + state.instituitionAdmin,
        instituitionStudents: state.mainAPI + state.instituitionStudents,
        createGroup: state.mainAPI + state.createGroup,
        createSubGroup: state.mainAPI + state.createSubGroup,
        createAdmin: state.mainAPI + state.createAdmin,
        getUserGroupList: state.mainAPI + state.getUserGroupList,
        createAdminSubgroup: state.mainAPI + state.createAdminSubgroup,
        enrollGroupUser: state.mainAPI + state.enrollGroupUser,
        enrollSubGroupUser: state.mainAPI + state.enrollSubGroupUser,
        deleteGroupUser: state.mainAPI + state.deleteGroupUser,
        deleteSubGroupUser: state.mainAPI + state.deleteSubGroupUser,
        getEnrollUsersList: state.mainAPI + state.getEnrollUsersList,
        getEnrollSubgroupUsersList:
          state.mainAPI + state.getEnrollSubgroupUsersList,
        updateSubgroup: state.mainAPI + state.updateSubgroup,
        groupSubgroups: state.mainAPI + state.groupSubgroups,
        createCourse: state.mainAPI + state.createCourse,
        updateCourse: state.mainAPI + state.updateCourse,
        getCourseWithUsers: state.mainAPI + state.getCourseWithUsers,
        createIntructor: state.mainAPI + state.createIntructor,
        createStudent: state.mainAPI + state.createStudent,
        deleteCourseUser: state.mainAPI + state.deleteCourseUser,
        attendenceType: state.mainAPI + state.attendenceType,
        createworkdays: state.mainAPI + state.createworkdays,
        createSessions: state.mainAPI + state.createSessions,
        attendenceDates: state.mainAPI + state.attendenceDates,
        attendences: state.mainAPI + state.attendences,
        attendencesList: state.mainAPI + state.attendencesList,
        lessonplans: state.mainAPI + state.lessonplans,
        lessonList: state.mainAPI + state.lessonList,
        deliveryType: state.mainAPI + state.deliveryType,
        attachCourseLessonPlan: state.mainAPI + state.attachCourseLessonPlan,
        detachCourseLessonPlan: state.mainAPI + state.detachCourseLessonPlan,
        attachDeliveryType: state.mainAPI + state.attachDeliveryType,
        detachDeliveryType: state.mainAPI + state.detachDeliveryType,
        getAttendenceDatesLessonPlans:
          state.mainAPI + state.getAttendenceDatesLessonPlans,
        getAttendenceDatesDeliveryTypes:
          state.mainAPI + state.getAttendenceDatesDeliveryTypes,
        institutionSettings: state.mainAPI + state.institutionSettings,
        programOutcome: state.mainAPI + state.programOutcome,
        courseModules: state.mainAPI + state.courseModules,
        programSpecificOutcome: state.mainAPI + state.programSpecificOutcome,
        courseOutcomes: state.mainAPI + state.courseOutcomes,
        getCourseOutcomes: state.mainAPI + state.getCourseOutcomes,
        getCourseModules: state.mainAPI + state.getCourseModules,
        copoMapping: state.mainAPI + state.copoMapping,
        getcopoMapping: state.mainAPI + state.getcopoMapping,
        copsoMapping: state.mainAPI + state.copsoMapping,
        getcopsoMapping: state.mainAPI + state.getcopsoMapping,
        emailTransaction: state.mainAPI + state.emailTransaction,
        examination: state.mainAPI + state.examination,
        universityexamination: state.mainAPI + state.universityexamination,
        examQuestions: state.mainAPI + state.examQuestions,
        examChoices: state.mainAPI + state.examChoices,
        examAnswers: state.mainAPI + state.examAnswers,
        googleEvent: state.mainAPI + state.googleEvent,
        assignment: state.mainAPI + state.assignment,
        assignmentQuestions: state.mainAPI + state.assignmentQuestions,
        assignmentAnswers: state.mainAPI + state.assignmentAnswers,
        studentFeedbackSurvey: state.mainAPI + state.studentFeedbackSurvey,
        studentFeedbackSurveySyncTeachers:
          state.mainAPI + state.studentFeedbackSurveySyncTeachers,
        studentFeedbackSurveySyncParticipants:
          state.mainAPI + state.studentFeedbackSurveySyncParticipants,
        surveyQuestions: state.mainAPI + state.surveyQuestions,
        examCategory: state.mainAPI + state.examCategory,
        surveyApprovals: state.mainAPI + state.surveyApprovals,
        approvalQuestions: state.mainAPI + state.approvalQuestions,
      };
    },
    getMyProfile(state) {
      return state.myProfile;
    },
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setMyProfile(state, payload) {
      state.myProfile = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    logoutUser() {
      localStorage.removeItem("token");
      if (localStorage.getItem("userProfileSkipped") != null) {
        localStorage.removeItem("userProfileSkipped");
      }
    },
  },
  actions: {
    async logoutUser(context) {
      await context.commit("loadInstituteData");
      let userPermissionsData = localStorage.getItem("userPermissions");
      if (userPermissionsData) {
        localStorage.removeItem("userPermissions");
      }
      let institutionUserRolesArray = localStorage.getItem(
        "institutionUserRolesArray"
      );
      if (institutionUserRolesArray) {
        localStorage.removeItem("institutionUserRolesArray");
      }
      await context.commit("logoutUser");
      await context.commit("loadInstituteData");
      await router.push("/");
      router.go();
      // window.location.reload();
      // store.replaceState({});
    },
    async getUser(context) {
      let user = {};
      const url = store.getters.getAPIKey.userDetails;
      await axios.get(url).then((response) => {
        user = response.data;
      });
      context.commit("setUser", user);
    },
    async getMyProfile(context) {
      let myProfile = {};
      const url = store.getters.getAPIKey.checkUserProfile;
      await axios.get(url).then((response) => {
        myProfile = response.data;
      });
      context.commit("setMyProfile", myProfile);
    },
    async storeUserProfile(context, payload) {
      const profileCreation = await axios
        .put(
          store.getters.getAPIKey.createProfile + context.state.myProfile.id,
          payload
        )
        .then((res) => {
          res;

          return {
            status: true,
            res: res,
          };
        })
        .catch((err) => {
          err;
          return {
            status: false,
            res: err.response.data.errors,
          };
        });
      if (profileCreation.status) {
        context.dispatch("getMyProfile");
      }
      return profileCreation;
    },
  },
});
